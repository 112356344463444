import React, { useState, useEffect } from 'react';
import useWakeLock from '../Wake_Lock/Wake_Lock.jsx';

import './App.css';
// import './Leaflet.css' // Стили для корректного расположения карты и элементов

// import Headers from '../Header/Header.jsx';
import MapLeaflet from '../MapLeaflet/MapLeaflet';


import Context from '../Context/PointsContext.js';

// Модули
import Menu from '../Menu/Menu.jsx';

// import FullScreen from '../Menu/FullScreen.jsx';


// Загрузка данных
import GetDataLS from '../LocalStorage/LocalStorage.jsx'

import WS from '../WS/Ws_client.jsx'

function App() {
  // Глобальная переменная
  // const [latlong, setLatlong] = useState('')
  const [context, setContext] = useState({
    'route': {
      'A': [],
      'B': [],
      'status': false
    },
    'id_car': localStorage.getItem('id_car') || ''
  });

  // const wakeLock = useWakeLock();
  // const [isWakeLockActive, setIsWakeLockActive] = useState(localStorage.getItem('wakeLockActive') === 'true');

  // useEffect(() => {
  //   if (wakeLock) {
  //     console.log('act');
  //     setIsWakeLockActive(true);
  //   } else {
  //     console.log('No act');
  //     setIsWakeLockActive(false);

  //   }
  // }, [wakeLock]);

  return (<>
    <Context.Provider value={{ context, setContext }}>
      {/* Попытка в локальное хранилище indexDB */}
      {/* <GetDB /> */}
      {/* Сначала грузим данные */}
      <GetDataLS />

      {/* <Headers /> */}
      <MapLeaflet />
      <Menu />
      {/* <Test /> */}
      {/* <Agrohim /> */}
      {/* <WS /> */}
    </Context.Provider>
  </>
  )
}

export default App;
