import React from 'react';
const apiHost = process.env.REACT_APP_API_HOST;

export default function DownloadButton({ fileUrl }) {
    const handleClick = async () => {
        try {
            // Отправляем GET запрос на сервер для загрузки файла
            const response = await fetch(`${apiHost}${fileUrl}`);

            // Преобразуем данные в JSON-строку
            const jsonData = JSON.stringify(response);
            console.log(jsonData);

            // const blob = await response.blob();

            // Создаем Blob из JSON-строки
            const blob = new Blob([response], { type: 'application/json' });

            // Создаем URL объект для Blob
            const url = window.URL.createObjectURL(blob);

            // Создаем ссылку для скачивания
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileUrl}.json`);

            // Добавляем ссылку в документ и эмулируем клик для скачивания файла
            document.body.appendChild(link);
            link.click();

            // Удаляем ссылку из документа после скачивания
            document.body.removeChild(link);
        } catch (error) {
            console.error('Ошибка при загрузке файла:', error);
        }
    };

    return (
        <button onClick={handleClick}>Скачать файл</button>
    );
};

// export default DownloadButton;
