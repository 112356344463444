// Навигация по полям
// Строит маршрут. Вся логика здесь. На вход только подается контекст

// Свой геокодер. Запрос - ответ. Клиент - сервер

import React, { useEffect, useState, useContext } from 'react';

import L from 'leaflet';
import 'leaflet-routing-machine';
import { useMap, useMapEvent } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';

// import icon from './icon.png'
import pointA from './pointA.png'
import pointB from './pointB.png'

// Импортируем контекст с кооридинатой
import ValueContext from '../../Context/PointsContext';

// Работаем через контекст
// import Context from '../Context/PointsContext';

const MapWithRouting = (props) => {
    const [routeControl, setRouteControl] = useState(null);

    // Координаты
    const { context } = useContext(ValueContext);
    // console.log(context);
    const route = context?.route

    // Карта leaflet 
    const map = useMap();
    // Убираем флаг. Мы не политики.
    map.attributionControl.setPrefix('Leaflet')

    // Строит маршрут
    const handleButtonClick = () => {
        // Обновляем маршрут
        if (routeControl) {
            routeControl.setWaypoints([
                L.latLng(route?.A),
                L.latLng(route?.B)
            ]);
            // console.log('Маршрутизатор обновлен', context);
        }
        else {
            // иначе создаем новый блок управления
            const control = L.Routing.control({
                waypoints: [
                    L.latLng(route?.A),
                    L.latLng(route?.B)
                ],
                routeWhileDragging: true, // Разрешаем перетаскивание маркера
                createMarker: function (i, waypoint, n) {
                    // Создаем свою иконку для маркеров
                    const myIcon = new L.Icon({
                        iconUrl: pointA,
                        iconSize: [32, 32],
                        iconAnchor: [16, 16],
                        popupAnchor: [0, -16],
                    });

                    // Возвращаем маркер с нашей иконкой
                    return L.marker(waypoint.latLng, {
                        draggable: true,
                        icon: myIcon,
                    });
                }
            }).addTo(map);
            control.hide();
            // control.showAlternatives(true); 

            setRouteControl(control);
            console.log('Маршрутизатор создан!');

            // console.log( control.getWaypoints());
        }

        // Прослушивание события, когда маршрут был построен
        // routeControl.on('routesfound', function (e) {
        //     // Получение массива найденных маршрутов
        //     var routes = e.routes;
        //     // Перебор каждого маршрута
        //     routes.forEach(function (route) {
        //         // Получение общего времени прохождения маршрута и вывод в консоль
        //         var totalDistance = route.summary.totalDistance; // Общее время прохождения
        //         console.log('Total Distance:', totalDistance);
        //     });
        // });
    };

    // Конец маршрутизации
    const handleFinishRoute = () => {
        if (routeControl) {
            // Удаляем маршрут
            routeControl.remove()
        }
    }

    // Загрузить последнюю позицию из локального хранилища, если она там есть
    useEffect(() => {
        const savedPosition = JSON.parse(localStorage.getItem('mapPosition'));
        const savedZoom = JSON.parse(localStorage.getItem('mapZoom'));
        if (savedPosition && savedZoom) {
            map.setView(savedPosition, savedZoom);
        }
    }, []);

    // Слушатель события перемещения и изменения зума карты
    useMapEvent('moveend', (event) => {
        // Получить текущие координаты центра и зум карты
        const map = event.target;
        const currentPosition = map.getCenter();
        const currentZoom = map.getZoom();
        // Сохранить текущие координаты и зум в локальном хранилище
        localStorage.setItem('mapPosition', JSON.stringify(currentPosition));
        localStorage.setItem('mapZoom', JSON.stringify(currentZoom));
    });
    // Мониторинг статуса маршрутизации
    useEffect(() => {
        console.log('status', route.status);
        if (routeControl) {
            // Удаляем маршрут
            // console.log('Маршрут', routeControl);
            // routeControl.remove()
        }

    }, [route.status])

    // Если данные есть, то автоматически прокладываем маршрут
    useEffect(() => {
        const autorouteStr = localStorage.getItem('autoroute');
        // const autoroute = autorouteStr === 'true'; // Проверяем, равна ли строка 'true'
        const autoroute = true

        const route = context?.route

        // console.log(autoroute);
        if (route?.A && route?.B && autoroute) {
            handleButtonClick();
            // console.log('Построение маршрута');
            // setData(null) // Удаяем сообщение
        }
        else {
            // console.log(route?.A, route?.B);

            // console.log('Недостаточно данных для построения маршрута');
            if (!route?.A) {
                // setData('Недостаточно данных. Включите GPS')
                console.log('Недостаточно данных. Включите GPS')
            }
            else if (!route?.B) {
                // setData('Недостаточно данных. Выберете задание')
                console.log('Недостаточно данных. Выберете задание')
            }

            else if (!autoroute) {
                console.log('Автопостроение маршрута отключено!')
            }

        }


    }, [props])


    // // Обновление первой точки (начальных координат)
    // const updateStartPoint = () => {
    //     // Получение текущего массива waypoints
    //     let waypoints = routeControl.getWaypoints();

    //     // Замена первой точки новыми координатами
    //     waypoints.splice(0, 1, startCoords);

    //     // Установка обновленного массива waypoints
    //     routeControl.setWaypoints(waypoints);
    // };

    return (
        <div>.</div>
    );
};

export default MapWithRouting;
