// Контейнер для карты компонетов (обертка со стилями)
import React, { useState } from 'react';
import { Box } from '@mui/system';

import Metrica from '../Metrica/Login.jsx';

import LeafletComp from './LeafletComp.jsx';

import CarNumber from '../Car/CarNumber.jsx';

export default function MapLeaflet() {

  // const [car_id, setCar_id] = useState(''); // Текущая позиция
  // // Для возврата car id
  // const handleChildValueChange = (car_id) => {
  //   setCar_id(car_id);
  // };

  return (
    // Обёртка
    <>
      {/* <Box sx={{ bgcolor: "#282828", width: '100%', height: '100vh' }}> */}
      {/* <Metrica/> */}
      {/* <CarNumber onValueChange={handleChildValueChange} /> */}
      <LeafletComp />

      {/* <Test/> */}
      {/* </Box> */}
    </>
  );
}