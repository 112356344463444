import React, { useEffect, useState } from 'react';

import { Card, CardActionArea, Button } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import SettingsIcon from '@mui/icons-material/Settings';

import LSSwitch from '../Tools/LSswitch.jsx'

import Downloads from '../Tools/Downloads.jsx'


export default function Home() {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);

    const version = process.env.REACT_APP_VERSION;

    const handleOpen = () => {
        // console.log('Settings');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleLS = () => {
        localStorage.clear();
        console.log("Хранилище пусто!");
    };


    return (
        <Card sx={{ minWidth: 35, maxHeight: 64, p: 0.5, borderRadius: 2 }}>
            <CardActionArea onClick={handleOpen}>
                <SettingsIcon fontSize='large' />
            </CardActionArea>

            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Настройки</DialogTitle>

                <DialogContent >
                    {/* localStorage.getItem('autoroute') */}
                    {/* <LSSwitch label={'Автомаршрут'} storageKey={'autoroute'} /> */}
                    <Button onClick={handleLS} color="primary">
                        Очистить хранилище
                    </Button>
                    <Downloads fileUrl={'/calibration/206860'} />
                    <p>Версия билда: {version}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}