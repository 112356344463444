import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import icon from './cursor_my_car.png';

import { Box, IconButton } from '@mui/material';

import GpsOffIcon from '@mui/icons-material/GpsOff';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Alert from '@mui/material/Alert';

// Работаем через контекст
import PointsContext from '../Context/PointsContext';

const stl = { bgcolor: "#ffffffbb", padding: 1, borderRadius: 8 };

const Icon = ({ statusIcon }) => {
  let iconComponent;
  if (statusIcon === 'off') {
    iconComponent = <GpsOffIcon fontSize="large" sx={stl} />;
  } else if (statusIcon === 'on') {
    iconComponent = <GpsNotFixedIcon color="primary" fontSize="large" sx={stl} />;
  } else if (statusIcon === 'fix') {
    iconComponent = <GpsFixedIcon color="primary" fontSize="large" sx={stl} />;
  } else if (statusIcon === 'err') {
    iconComponent = <GpsOffIcon color="red" fontSize="large" sx={stl} />;
  }
  return iconComponent;
};

const GpsNavigation = (props) => {
  const requiredAccuracy = 300;

  const [isGpsEnabled, setIsGpsEnabled] = useState(false);
  const [statusIcon, setStatusIcon] = useState('off');
  const [gpsStatus, setGpsStatus] = useState('');
  const [coordinates, setCoordinates] = useState(null);
  const [watch, setWatch] = useState(null);

  const { setContext } = useContext(PointsContext);

  const map = useMap();
  const circleOptions = {
    color: 'blue',
    fillColor: 'blue',
    fillOpacity: 0.05,
    radius: coordinates?.accuracy,
    weight: 1 // толщина контура
  };

  const Icon_GPS = useMemo(() => (
    new L.Icon({
      iconUrl: icon,
      iconSize: [24, 24],
      iconAnchor: [12, 12],
      popupAnchor: [0, -12],

    })
  ), []);

  useEffect(() => {
    const watchPositionSuccess = (position) => {
      const newCoordinates = { latitude: position.coords.latitude, longitude: position.coords.longitude, accuracy: position.coords.accuracy, speed: position.coords.speed, heading: position.coords.heading };
      // console.log(position); 
      if (newCoordinates.accuracy <= requiredAccuracy) {
        setCoordinates(newCoordinates);
        // setGpsStatus(`${newCoordinates.latitude.toFixed(4)}, ${newCoordinates.longitude.toFixed(4)}, Ск. ${(newCoordinates.speed || -1).toFixed(2)}, Курс: ${(newCoordinates.heading || -1).toFixed()}`);
        setGpsStatus(`Ск. ${(newCoordinates.speed|| -1).toFixed(2)}, Курс: ${(newCoordinates.heading || -1).toFixed()}`);
        map.setView([newCoordinates.latitude, newCoordinates.longitude], map.getZoom());
        setStatusIcon('fix');

        // Отправляем координаты точки А 
        // props.point('A', [newCoordinates.latitude, newCoordinates.longitude]);

        // Обновляем координаты точки А
        setContext(prevState => ({
          ...prevState,
          'route': {
            // Сохраняем ранее занесенные сообщения
            ...prevState.route,
            'A': [newCoordinates.latitude, newCoordinates.longitude] || [50.206, 127.928],
            'A_name': `A: ${[newCoordinates.latitude?.toFixed(3), newCoordinates.longitude?.toFixed(3)]}`
          }
        }))

      } else {
        setGpsStatus(`Низкая точность (${newCoordinates.accuracy.toFixed()} м)`);
        setStatusIcon('on');
      }
    };

    const watchPositionError = (error) => {
      setGpsStatus(`Ошибка: ${error.message}`);
      setStatusIcon('off');
      toggleGps();
      setStatusIcon('err');
      if (error.message === 'User denied Geolocation') {
        alert('Отслеживание запрещено. Предоставьте доступ!');
      } else {
        alert(gpsStatus);
      }
    };

    // Основа
    if (navigator.geolocation) {
      if (isGpsEnabled) {
        // Повышаем точность и отказоустойчивость
        var geo_options = {
          enableHighAccuracy: true,
          maximumAge: 30000,
          timeout: 27000,
        };

        const watchId = navigator.geolocation.watchPosition(watchPositionSuccess, watchPositionError, geo_options);
        // id для отмены
        setWatch(watchId)
        console.log("start watchId:", watchId);

        // return () => navigator.geolocation.clearWatch(watchId);
      } else {
        setGpsStatus('GPS отключен');
        setCoordinates(null);
        setStatusIcon('off');
      }
    } else {
      setGpsStatus('GPS не поддерживается в вашем браузере');
    }
  }, [isGpsEnabled, map, requiredAccuracy]);

  useEffect(() => {
    let gpsMarker;
    let gpsCircle;

    if (coordinates) {
      gpsMarker = L.marker([coordinates.latitude, coordinates.longitude], { icon: Icon_GPS, rotationAngle: coordinates.heading }).bindPopup(
        `Широта: ${coordinates.latitude}<br />Долгота: ${coordinates.longitude}<br />Скорость: ${coordinates?.speed || -1}<br />`
      ).addTo(map);

      gpsCircle = L.circle([coordinates.latitude, coordinates.longitude], circleOptions).addTo(map);
    }

    return () => {
      if (gpsMarker) {
        map.removeLayer(gpsMarker);
        map.removeLayer(gpsCircle);
      }
    };
  }, [coordinates, map, Icon_GPS, circleOptions]);

  const toggleGps = () => {
    setIsGpsEnabled(!isGpsEnabled);
  };

  // При изменении статуса GPS
  useEffect(() => {
    if (watch) {
      navigator.geolocation.clearWatch(watch);
      console.log("stop watchId:", watch);
      setWatch(null)
    }
    // else {
    //   console.log("null watchId:", watch);
    // }
  }, [isGpsEnabled])

  return (
    <Box sx={{ position: 'absolute', top: 60, right: 8, zIndex: 1000, textAlign: 'right' }}>
      <IconButton aria-label="GPS" onClick={toggleGps} >
        <Icon statusIcon={statusIcon} />
      </IconButton>
      <div>
        <p>{gpsStatus}</p>
      </div>
    </Box>
  );
};

export default GpsNavigation;
