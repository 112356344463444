import React, { useState, useEffect } from 'react';
import { Card, Typography, Box, CardActionArea, CardContent, Dialog } from '@mui/material';

import Entrance from '../LineWithWork/Entrance.jsx';

export default function Geozona({ props }) {
    const [data, setData] = useState(null);
    const url = '/fields'

    useEffect(() => {
        const fetchData = async () => {
            // const cachedData = localStorage.getItem(url);
            const cachedData = localStorage.getItem(url);
            if (!cachedData) {
                console.log('Нет данных!');
            } else {
                setData(JSON.parse(cachedData));
                // console.log(data);
            }
        }
        fetchData();
    }, []);

    // // Ищем конечную точку
    // function findEnterancesById(id) {
    //     console.log('id:', id);
    //     // Список конечных точек
    //     const field = data.find(dataItem => dataItem?.id == id)
    //     // Проверка на родителя
    //     if (field?.parent == id) {
    //         console.log(id, '- это родитель');
    //         return field?.enterances;
    //     }
    //     // Если не родительское, то ищем родительское с подъездами
    //     // else {
    //     //     // console.log("Родитель:", field.parent);
    //     //     return data.find(dataItem => dataItem?.id == field?.parent)?.enterances
    //     // }
    // }



    // console.log(data)
    return (
        <>
            {/* Список полей */}
            {/* item.id  - id поля*/}
            {/* {data} */}


            {props?.fields && props?.fields.map((item, index) => (
                <Card key={index} sx={{ minWidth: 340, p: 2, border: '1px dashed grey' }}>
                    {/* Грузим данные слокального хранилища */}
                    {/* {data.map((item, index) => ('{item.name}'))} */}
                    <Typography >
                        {/* {data.name} */}
                        Место: {data && data.find(dataItem => dataItem?.as_id == item.id)?.name|| "Не найдено"}

                    </Typography>

                    {item.actions && (
                        <Typography >
                            Действие: {item.actions}
                        </Typography>
                    )}

                    <Typography >
                        {/* Поиск заездов по id родительского поля */}
                        <Entrance key={item.id} enterances={[data && data.find(dataItem => dataItem?.as_id == item.id)?.parent || 0]} />
                    </Typography>

                </Card>
            ))}
        </>
    );
}
