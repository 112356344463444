// Распалагается поверх карты в виде строки. Содержит тематические блоки (компоненты)

import { Box, Card } from '@mui/material';
import React from 'react';

// Импортируем модули для горизонтального меню
import Home from '../MapLeaflet/Home';
import CarNumber from '../Car/CarNumber.jsx';
import WorkPanel from '../LineWithWork/WorkPanel.jsx';
import Settings from '../Menu/Settings';
import FullScreen from '../Menu/FullScreen.jsx';
import DUT from '../DUT/DUT.jsx'
import Navigation from './Navigation.jsx'
import RouteMashine from '../MapLeaflet/Route/RouteLeaflet.jsx'



const Menu = () => {
    return (
        <Box sx={{
            ...styles.fixedContainer,
            // border: '1px dashed red', // Отладка
        }}>
            <Box component="section" sx={{
                ...styles.container,
                // border: '1px dashed green', // Отладка
            }}>
                <CarNumber />
                <Navigation />
                <WorkPanel />
                <Home />
                {/* <RouteMashine /> */}
                <Settings />
                <FullScreen />
                {/* <DUT/> */}
                

            </Box>
        </Box>
    )
};
export default Menu;

const styles = {
    container: {
        display: 'flex',
        gap: 1,
        padding: 1,

        overflowX: 'auto',
        overflowY: 'clip',
        WebkitOverflowScrolling: 'touch', // Для Safari
        scrollbarWidth: 'thin', // Для Firefox
        scrollbarColor: '#8f8f8f80 transparent', // Для Firefox
    },
    fixedContainer: {
        position: 'fixed',
        bottom: '0',
        left: '0',
        right: '0',
        // width:'100%',
        zIndex: 1000
    },
};