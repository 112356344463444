// Сохраняем данные в LocalStorage

// Проверять версионость перед загрузкой

import React, { useState, useEffect } from 'react';
import './LoadingOverlay.css'; // Подключаем стили

const tablesToFetch = [
    { url: '/cars', name: 'машин' },
    { url: '/fields', name: 'полей' },
    { url: '/entrances', name: 'подъездов' },
    // { url: '/ws_all_cars', name: 'координат' }
    // Добавьте сюда другие таблицы, если необходимо
];

export default function GetTables() {
    const apiHost = process.env.REACT_APP_API_HOST;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [name, setName] = useState(null);

    console.log('Server:', apiHost);

    useEffect(() => {
        const fetchData = async () => {
            try {
                for (const table of tablesToFetch) {
                    // console.log(`Старт загрузки для ${table.name}`);
                    setName(table.name)
                    const response = await fetch(`${apiHost}${table.url}`);

                    if (!response.ok) {
                        throw new Error('Ответ сети был не "ok"');
                    }

                    const result = await response.json();
                    save_cars_id(table.url, result)
                    localStorage.setItem(table.url, JSON.stringify(result));

                    // console.log(`Загрузка для ${table.name} завершена`);
                }
            } catch (error) {
                console.error(error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <LoadingOverlay name={name} />;
    }

    if (error) {
        return <div>Ошибка: {error.message}</div>;
    }

    // return <div>Загрузка завершена</div>;
}



const LoadingOverlay = ({ name }) => {
    return (
        <div className="loading-overlay">
            <div className="loader"></div>
            <div> Загрузка {name}</div>
        </div>
    );
};


function save_cars_id(name, data) {
    // Для отображения номеров  
    if (name == '/cars') {
        const cars = data.map(item => ({ [item.as_id]: item.number }));
        localStorage.setItem(name + '_id', JSON.stringify(cars))
    }
}