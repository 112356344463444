// Панель с работой
import React, { useEffect, useState, useContext } from 'react';
import { Alert, Box } from "@mui/material";
import CardWork from './CardWork'

import PointsContext from '../Context/PointsContext';


// Импорт стилей
import '../styles/styles.css';

const APIHOST = process.env.REACT_APP_API_HOST;

export default function WorkPanel(car_id) {
    "Линия с карточками заданий"

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const { context, setContext } = useContext(PointsContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const car_id = localStorage.getItem('id_car')
                // console.log(car_id);
                const response = await fetch(`${APIHOST}/works/${car_id}`);

                if (!response.ok) {
                    throw new Error('Ответ сети не "ok"');
                }

                const result = await response.json();
                setData(result);
            } catch (error) {
                setError(error);
                console.log(error);
            }
        };

        fetchData();
    }, [context?.id_car]);

    if (error) {
        console.log("Error:", error.message);
        return (
            <Alert>"Error:", {error.message}</Alert>
        )
    }

    if (!data) {
        // console.log('Loading');
    }

    return (
        <>
            {/* <Box className="fixedContainer"> */}
            {/* <Box component="section" className="container"> */}
            {data && data.length > 0 ? (
                data.map((item, index) => (
                    <CardWork key={index} {...item} />
                ))
            ) : (
                <CardWork operation="Работа не найдена" description="" />
            )}
            {/* </Box> */}
            {/* </Box> */}
        </>
    )
}

