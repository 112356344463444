// Развернутьь на весь экран
import React, { useState } from 'react';
import { Button, Card, CardActionArea } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

function FullScreenComponent() {
    const [full, setFull] = useState(false);

    // Получаем элемент, который вы хотите отобразить в полноэкранном режиме
    var elem = document.documentElement;

    // Функция для запроса полноэкранного режима
    function openFullscreen() {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari и Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
        setFull(true)
    }

    // Функция для выхода из полноэкранного режима
    function closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari и Opera */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
        }
        setFull(false)
    }

    return (
        <>
            {!full ? (
                <Card onClick={openFullscreen} sx={{ minWidth: 35, maxHeight: 64, p: 0.5, borderRadius: 2 }}>
                    <CardActionArea >
                        <FullscreenIcon fontSize='large' />
                    </CardActionArea>
                </Card>)
                :
                (<Card onClick={closeFullscreen} sx={{ minWidth: 35, maxHeight: 64, p: 0.5, borderRadius: 2 }}>
                    <CardActionArea >
                        <FullscreenExitIcon fontSize='large' />
                    </CardActionArea>
                </Card>)
            }
        </>
    );
}

export default FullScreenComponent;
