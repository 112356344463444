import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@mui/material';
import { Card, Button, Typography, Box, Alert, CardActionArea, CardContent } from '@mui/material';

import Geozona from './Geozona'

import PointsContext from '../Context/PointsContext';

// import Geozona from './GZ'

// Карточка с заданием
export default function CardWork(props) {
    "Карточка с работой и полями"
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);

    const { context, setContext} = useContext(PointsContext);

    // Список геозон (оптимизация вызовов) 
    useEffect(() => {
        setData(props)
        // console.log("props", props)
        // console.log("data", data)
        // Прорисовка задания?
    }, [])

    // Костыль на закрытие
    useEffect(() => {
        console.log('Окно:', context?.open );
        if (context?.open == false) {
            setOpen(false)
        }
    }, [context?.open])


    const handleOpen = () => {
        // console.log('open');
        setOpen(true);
        setContext(prevState => ({
            ...prevState,
            'open':true
        }))
    };

    const handleClose = () => {
        setOpen(false);
    };
    // console.log(props.zones);

    // Приводим дату и время в норму
    const options = { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' };
    const from = new Date(props.from).toLocaleDateString('ru-RU', options);
    const to = new Date(props.to).toLocaleDateString('ru-RU', options);
    // console.log(from, to)

    // if (!data) {
    //     console.log('Loading');
    // }

    return (
        <Card sx={{ minWidth: 140, maxHeight: 64, p: 0.5, borderRadius: 2 }} >
            <CardActionArea onClick={handleOpen}>
                <Typography sx={{ fontSize: 13, p: 0, color: 'green' }} style={{ lineHeight: '1' }}>
                    {props?.operation}
                </Typography>
                <Typography sx={{ fontSize: 12 }} style={{ lineHeight: '1.2' }}>
                    {props?.description}
                </Typography>
                <Typography sx={{ fontSize: 12 }} style={{ lineHeight: '1.2' }}>
                    {from}
                </Typography>
            </CardActionArea>

            <Dialog open={open} onClose={handleClose}  >
                <DialogTitle>{props?.operation}</DialogTitle>

                <DialogContent >
                    <Typography variant="body2" >
                        {props?.description}
                    </Typography>
                    {props?.tool && (
                        <Typography variant="body2">
                            Инструмент: {props.tool}
                        </Typography>
                    )}
                    {props?.from && (
                        <Typography variant="body2" >
                            Начало: {from}
                        </Typography>
                    )}
                    {props?.to && (
                        <Typography variant="body2" >
                            Окончание: {to}
                        </Typography>
                    )}
                    <Typography variant="body2" >

                    </Typography>
                    {/* Отправляем список полей для формирования */}
                    {props.fields ? (
                        <Geozona props={props} />
                    ) : (
                        <li>Нет данных о полях или конечных точках</li>
                    )
                    }

                    {/* {data.zones && Array.isArray(data.zones) && data.zones.length > 0 ? (<>123</>) : (<>No</>)} */}

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}