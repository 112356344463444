// Переключатель с памятью

import React, { useState, useEffect } from 'react';
import { Switch, FormControlLabel } from '@mui/material';

const LSSwitch = ({ label, storageKey }) => {
  const [checked, setChecked] = useState(
    localStorage.getItem(storageKey) === 'true' // Проверяем значение в localStorage
  );

  useEffect(() => {
    // Сохраняем текущее состояние в localStorage при изменении
    localStorage.setItem(storageKey, checked);
  }, [checked, storageKey]);

  const handleChange = () => {
    setChecked((prev) => !prev); // Инвертируем текущее состояние
  };

  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={handleChange} />} // disabled
      label={label}
    />
  );
};

export default LSSwitch;
