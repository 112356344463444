import React, { useEffect, useState, useContext } from 'react';
import { MapContainer, TileLayer, useMapEvents, GeoJSON, LayersControl, AttributionControl } from 'react-leaflet';
// import LineWithWork from '../LineWithWork/LineWithWork.jsx';
// import Fields from './Geojson/Geojson';


import CarNumber from '../Car/CarNumber.jsx';
import RouteMashine from './Route/RouteLeaflet.jsx';
// import zIndex from '@mui/material/styles/zIndex';

// import Fields from './Layers/Fields.jsx'
import Processing from './Layers/Processing.jsx'
// import Entrance from './Layers/Entrance.jsx'
// import Road from './Layers/Road.jsx'
import GPS from './GpsNavigation.jsx'
// Местоположение техники
import Ws_client from '../WS/Ws_client.jsx'

// Импортируем контекст с кооридинатой
import ValueContext from '../Context/PointsContext.js';

const { BaseLayer, Overlay } = LayersControl;

const LeafletComp = () => {
  const position = JSON.parse(localStorage.getItem('home')) || [50.2, 127.85]; // Домашняя точка

  const [clickedPosition, setClickedPosition] = useState(null);

  const [car_id, setCar_id] = useState(''); // Текущая позиция
  // Для возврата car id
  const handleChildValueChange = (car_id) => {
    setCar_id(car_id);
  };

  // Обработчики событий карты
  // const Handlers = () => {

  //   // Обработчики

  //   // const handlerClick = (e) => {
  //   //   "Обработка клика по карте"
  //   //   setClickedPosition(e.latlng);
  //   //   // console.log('Кликнуто по карте:', e.latlng);
  //   // }

  //   // // События карты
  //   // useMapEvents({
  //   //   // click: handlerClick,
  //   //   // Другие события...
  //   // })
  //   return null
  // }

  return (
    <>
      <MapContainer center={position} zoom={11} style={{ height: '100vh', width: '100%' }}>
        {/* paddingTop: '50px' */}

        {/* <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      /> */}

        {/* <TileLayer
        url="http://localhost:8080/geoserver/gwc/service/tms/1.0.0/Ardbot%3Acadastr@WebMercatorQuad/{z}/{x}/{y}.png"
        tms='true'
        attribution='geoserver'
      /> */}

        <LayersControl position="topleft">
          <BaseLayer checked name="OSM">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // attribution='<a href="https://www.openstreetmap.org/copyright">OSM</a>'
            />
          </BaseLayer>

          <Overlay checked name="БПЛА">
            <TileLayer
              url="https://ankmap.ru:8443/tiles/UAV/{z}/{x}/{y}.png"
              attribution='<a href="https://ankmap.ru/">БПЛА</a>'
            />
          </Overlay>

          {/* <Overlay name="БПЛА 2">
            <TileLayer
              url="https://ardbot.nextgis.com/api/component/render/tile?resource=149&nd=204&z={z}&x={x}&y={y}"
              attribution='<a href="https://ardbot.nextgis.com">Nextgis UAV</a>'
            />
          </Overlay> */}

          <Overlay checked name="Поля">
            <TileLayer
              url="https://ardbot.nextgis.com/api/component/render/tile?resource=156&nd=204&z={z}&x={x}&y={y}"
              
            />
          </Overlay>

          {/* <Overlay name="Fields">
            <Fields />
          </Overlay> */}

          {/* Заезды */}
          {/* <Overlay name="Entrance">
            <Entrance />
          </Overlay> */}

          {/* <Overlay name="Трек за сутки">
            <Processing />
          </Overlay> */}

          <Overlay checked name="Дороги (автобусы)">
            <TileLayer
              name="Дороги (автобусы)"
              url="https://ardbot.nextgis.com/api/component/render/tile?resource=183&nd=204&z={z}&x={x}&y={y}"
              attribution='<a href="https://ardbot.nextgis.com/resource/139/display">Дороги</a>'
              opacity='1'
            />
          </Overlay>

          <Overlay checked name="Заезды на поля">
            <TileLayer
              name="Заезды на поля"
              url="https://ardbot.nextgis.com/api/component/render/tile?resource=184&nd=204&z={z}&x={x}&y={y}"
              attribution='<a href="https://ardbot.nextgis.com/">Заезды</a>'
              opacity='1'
              minZoom={14}
            />
          </Overlay>
          



          <Overlay name="Урожайность">
            <TileLayer
              name="Урожайность"
              url="https://ardbot.nextgis.com/api/component/render/tile?resource=113,133,131,109,107,105&nd=204&z={z}&x={x}&y={y}"
              attribution='<a href="https://ardbot.nextgis.com/resource/93/display">Урожайность</a>'
            />
          </Overlay>

          <Overlay name="Claas">
            <TileLayer
              name="Claas"
              url="https://ardbot.nextgis.com/api/component/render/tile?resource=123,120,118,116&nd=204&z={z}&x={x}&y={y}"
              attribution='<a href="https://ardbot.nextgis.com/resource/93/display">Claas</a>'
              minZoom={12}
            />
          </Overlay>

          <Overlay  name="ЖВК. Объекты">
            <TileLayer
              name="ЖВК"
              url="https://ardbot.nextgis.com/api/component/render/tile?resource=148&nd=204&z={z}&x={x}&y={y}"
              attribution='<a href="https://ardbot.nextgis.com">Объекты</a>'
              minZoom={12}
            />
          </Overlay>
         

          <Overlay name="Алиасы">
            <TileLayer
              name="Алиасы"
              url=" https://ardbot.nextgis.com/api/component/render/tile?resource=40&nd=204&z={z}&x={x}&y={y}"
              attribution='<a href="https://ardbot.nextgis.com">Алиасы</a>'
              minZoom={12}
            />
          </Overlay>

          <Overlay name="Машины">

          </Overlay>

        </LayersControl>
        <GPS />
        <RouteMashine />
        <Ws_client/>
      </MapContainer>
    </>
  );
};

export default LeafletComp;