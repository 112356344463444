import React, { useState, useEffect, useContext } from 'react';
import { Box, Button } from '@mui/material';

// Алгоритм:
// Загружаем список заездов
// Получаем с локального хранилища справочник заездов

import PointsContext from '../Context/PointsContext';

import { useMap } from 'react-leaflet';

export default function Entrance(props) {
    const apiHost = process.env.REACT_APP_API_HOST;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const url = '/entrances';

    const { setContext } = useContext(PointsContext);

    // Для перемещения к полю
    // const map = useMap();

    useEffect(() => {
        // Узнаем точки заездов
        // const fetchData = async () => {
        //     try {
        //         const response = await fetch(`${apiHost}${url}`);

        //         if (!response.ok) {
        //             throw new Error('Ответ сети был не "ok"');
        //         }

        //         const result = await response.json();
        //         localStorage.setItem(url, JSON.stringify(result));
        //         setData(result);
        //     } catch (error) {
        //         console.error(url, error);
        //         setError(error);
        //     } finally {
        //         setLoading(false);
        //     }
        // };

        const cachedData = localStorage.getItem(url);
        if (!cachedData) {
            // fetchData();
            console.log(url, 'нет данных');
        } else {
            setData(JSON.parse(cachedData));
            setLoading(false);
        }

    }, []);

    // Формируем точки
    // const list

    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (error) {
        return <div>Ошибка: {error.message}</div>;
    }
    // console.log(props);

    function findEnterances(id) {
        // console.log(data);
        const enterance = data.find(dataItem => dataItem?.fields == id)
        // console.log(enterance?.fields);
        // console.log([enterance?.coord[1], enterance?.coord[0]]);

        // Обработчик клика, использующий анонимную функцию для передачи координат
        const handleClick = () => {
            // Вызываем функцию с передачей координат
            // handleRoute([enterance?.coordinates.lat, enterance?.coordinates.lon] || [50, 128]);
            // setContext([enterance?.coordinates.lat, enterance?.coordinates.lon] || [50, 127]);
            setContext(prevState => ({
                ...prevState,
                'route': {
                    ...prevState.route,
                    'B': [enterance?.coord[1], enterance?.coord[0]] || [50.206, 127.928],
                    'B_name': `B: ${enterance?.name}`,
                },
                // Устанавливаем состояние окна
                'open': false
            }))

            // map.setView([50, 128], 10)

        };

        return (
            <>
                <Button onClick={handleClick} key={props.index} variant="contained" size="small" sx={{ m: 1 }}>{enterance?.name}</Button>
                {/* <Button>2gis</Button> */}
            </>
        )
    }

    // Функция для обработки координат и выполнения необходимых действий
    const handleRoute = (latLng) => {
        // Построение маршрута
        console.log("pointB:", latLng);
    };

    return (
        <Box>
            {props.enterances && props.enterances.map((id, index) => findEnterances(id, index))}
            {/* <span>Value is: {value || '<Not set>'}</span> */}
        </Box>
    )
}