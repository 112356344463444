// https://it-dev-journal.ru/articles/kak-peredavat-dannye-mezhdu-komponentami-v-react-js

// Глобальный контекст.
// Структура:
// Словари
import { createContext } from 'react';

const context = createContext();

export default context;
