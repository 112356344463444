import React, { useEffect, useState, useMemo } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-rotatedmarker';

import cursor_green from './images/cursor_green.png'
import cursor_red from './images/cursor_red.png'

import point_green from './images/point_green.png'
import point_grey from './images/point_grey.png'
import point_red from './images/point_red.png'
import './number.css';

const apiHost = process.env.REACT_APP_API_HOST;
const ws_Host = process.env.REACT_APP_WS_HOST

const WebSocketComponent = () => {
    const [messages, setMessages] = useState([]);
    const [ws, setWs] = useState(null);
    const [reconnectTimer, setReconnectTimer] = useState(null);

    // useEffect(() => {
    //     const userId = localStorage.getItem('id_car') || 0
    //     const url = `${ws_Host}/ws/${userId}`;
    //     console.log('ws:', url);

    //     // url = 'ws://' + host + ':' + port + '/ws/connect/' + clientID
    //     const connect = () => {
    //         const websocket = new WebSocket(url);

    //         websocket.onopen = () => {
    //             console.log('WebSocket connection');
    //             if (reconnectTimer) {
    //                 clearInterval(reconnectTimer);
    //                 setReconnectTimer(null);
    //             }
    //             // Запрашиваем полный список
    //             // console.log('el', messages);

    //             // Костыль!
    //             const ws_all_cars = JSON.parse(localStorage.getItem('/ws_all_cars')) || []
    //             setMessages(ws_all_cars);

    //             return (
    //                 <Marker_car elements={ws_all_cars} cars_id={cars_number} />
    //             )
    //         };

    //         websocket.onmessage = (event) => {
    //             // Заменяем одинарные кавычки на двойные
    //             try {
    //                 const fixedJsonString = event.data.replace(/'/g, '"');
    //                 // console.log(event.data);
    //                 const data = JSON.parse(fixedJsonString)
    //                 setMessages(data);
    //             } catch {
    //                 console.log(event.data);
    //             }
    //         };

    //         websocket.onclose = () => {
    //             console.log('WebSocket connection closed');
    //             if (!reconnectTimer) {
    //                 const timer = setInterval(() => {
    //                     console.log('Повторное подключение к ws...');
    //                     connect();
    //                 }, 5000); // Периодичность попыток переподключения
    //                 setReconnectTimer(timer);
    //             }
    //         };

    //         websocket.onerror = (error) => {
    //             console.log('WebSocket error:', error);
    //         };
    //         setWs(websocket);
    //     };

    //     connect();

    //     return () => {
    //         if (ws) {
    //             ws.close();
    //         }
    //         if (reconnectTimer) {
    //             clearInterval(reconnectTimer);
    //         }
    //     };
    // }, [reconnectTimer]);

    // let requests = fetch(`${apiHost}/ws_all_cars`).then(response => response.json())
    // const responseData = await Promise.all(requests);
    // setMessages(responseData)

    // Импорт номеров машин
    const cars_number = JSON.parse(localStorage.getItem('/cars_id')) || []
    useEffect(async () => {
        // Костыль!
        // const ws_all_cars = JSON.parse(localStorage.getItem('/ws_all_cars')) || []
        // setMessages(ws_all_cars);

        const response = await fetch(`${apiHost}/ws_all_cars`);

        if (!response.ok) {
            throw new Error('Ответ сети был не "ok"');
        }
        const result = await response.json();

        setMessages(result)
    }, []);




    // const handleClickSendMessage = () => {
    //     if (ws) {
    //         ws.send('Hello, Server!');
    //     }
    // };

    return (
        <Marker_car elements={messages} cars_id={cars_number} />
    )
};

export default WebSocketComponent;

async function get_pos() {
    const response = await fetch(`${apiHost}/ws_all_cars`);

    if (!response.ok) {
        throw new Error('Ответ сети был не "ok"');
    }
    const result = await response.json();
    return result

}

const Marker_car = ({ elements, cars_id }) => {
    const [markers, setMarkers] = useState([]);
    const map = useMap();

    useEffect(() => {
        // const cars = localStorage.getItem('cars')
        // Перебираем элементы словаря elements
        for (const [key, value] of Object.entries(elements)) {

            // elements.forEach(element => {

            //     // Проверяем, существует ли уже маркер с идентификатором element.id
            const m = markers[key]
            //     // Найти объект с нужным id
            const car = cars_id.find(car => car[key] !== undefined);

            if (m) {
                // console.log('Update', element.id);
                m.setLatLng([value.position[1], value.position[0]]); // Обновляем его координаты
                updateMarkerIconAndRotation(m, cursor_green, value.azimuth);
                // setMarkers(prevState => ({ ...prevState, [element.id]: marker }))
                // Отображать технику за последнии 12 часов
            } else if (car && isDataOld(value?.time, 720)) {
                // console.log('Create', element.id);

                const tooltip = L.tooltip({
                    permanent: true,
                    direction: 'bottom',
                    offset: [0, 10],
                    opacity: 1,
                    className: 'license-plate-tooltip'
                }).setContent(get_number(cars_id, key))

                // Если маркер не существует, создаем новый маркер с указанными координатами и идентификатором
                // console.log(value);


                const marker = L.marker([value.position[1], value.position[0]], { icon: cursor(value), rotationAngle: value?.azimuth })
                marker.bindTooltip(tooltip)
                // console.log(value);
                marker.bindPopup(convert_date(value))
                marker.addTo(map) // Добавляет маркер на карту

                // Добавляем маркер в список
                setMarkers(prevState => ({ ...prevState, [key]: marker }))
            };
        }
        // });
        // console.log(elements);
        // console.log('cars:', markers, markers.length);
    }, [elements]);
    return null; // Так как компонент управляет картой, нет необходимости возвращать JSX
};

function convert_date(value) {
    // Преобразование Unix времени в миллисекунды
    const date = new Date(value?.time)

    // Получение даты и времени
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Месяцы начинаются с 0, поэтому добавляем 1
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    // Форматирование даты и времени
    const formattedDate = `Данные от: ${hours}:${minutes}:${seconds} ${day}.${month}.${year}`;
    return formattedDate
}
// Временой статус, Время отклонения
function isDataOld(time, offset = 10) {
    const currentTime = new Date().getTime(); // миллисекунды
    const time_difference = Math.floor((currentTime - time) / 60000); // В минутах
    // Актуально в течении 10 минут
    if (time_difference < offset) {
        // console.log('Актуально');
        return true
    }
    else {
        return false
    }
}

// Обработчик состояния курсора
function cursor(value) {
    const speed = value?.speed;
    const tm = value?.time;

    if (speed > 0) {
        // Проверяем, старые ли данные
        const iconUrl = isDataOld(tm) ? cursor_green : cursor_red;

        let newIcon = L.icon({
            // iconUrl: cursor_green, //
            iconUrl: iconUrl, //
            iconSize: [24, 24],
            iconAnchor: [12, 12],
            popupAnchor: [0, -12],
        });
        return newIcon;
    }

    else {
        // Проверяем, старые ли данные
        const iconUrl = isDataOld(tm) ? point_green : point_red;

        let newIcon = L.icon({
            iconUrl: iconUrl,
            iconSize: [16, 16],
            iconAnchor: [8, 8],
            popupAnchor: [0, -8],
        });
        return newIcon;
    }
    // else {
    //     // console.log('0', azimuth);
    //     let newIcon = L.icon({
    //         iconUrl: point_grey,
    //         iconSize: [16, 16],
    //         iconAnchor: [8, 8],
    //         popupAnchor: [0, -8],
    //     });
    //     return newIcon;
    // }
}


function get_number(cars_number, id) {
    // console.log(id);
    if (cars_number) {
        // Найти объект с нужным id
        const car = cars_number.find(car => car[id] !== undefined);
        // Получить значение по ключу id, если объект найден
        const value = car ? car[id] : 'X000XX';

        return value
    }
    else {
        return 'X000XX'
    }
}

// Функция для обновления иконки и угла поворота маркера
function updateMarkerIconAndRotation(marker, newIconUrl, newAzimuth) {
    const newIcon = L.icon({
        iconUrl: newIconUrl,
        iconSize: [24, 24],
        iconAnchor: [12, 12],
        popupAnchor: [0, -12],
    });

    marker.setIcon(newIcon);
    marker.setRotationAngle(newAzimuth); // Обновление угла поворота
}
