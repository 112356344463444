import React, { useState, useEffect, useContext } from 'react';
import { MenuItem, Select, ListSubheader, Card, Typography } from '@mui/material';
import PointsContext from '../Context/PointsContext';

const CarNumber = () => {
    // Изначальное значение номера автомобиля

    const [data, setData] = useState(null);
    const [selectedItem, setSelectedItem] = useState('');
    const { s, setContext } = useContext(PointsContext);

    const url = '/cars'
    // Проверяем хранилище
    useEffect(() => {
        const cachedData = localStorage.getItem(url);
        if (!cachedData) {
            console.log(url, 'нет данных!');
        } else {
            // console.log('cars', cachedData);
            setData(JSON.parse(cachedData));
        }
    }, []);

    // Устанавливает значение в список
    const handleChange = (event) => {
        const value = event.target.value;

        setSelectedItem(value);
        localStorage.setItem('id_car', value);

        setContext(prevState => ({
            ...prevState,
            'id_car': value || ''
        }))
    };
    // Здесь, перед  useEffect выполнять код нельзя. Будет ошибка 301

    useEffect(() => {
        // Проверка наличия сохраненного значения в localStorage
        const id_car = localStorage.getItem('id_car');

        if (id_car) {
            // Если значение найдено, установите его как выбранный элемент
            setSelectedItem(id_car);
            // console.log('id_car обнаружен', id_car);
        }
    }, []); // [] означает, что эффект будет вызываться только при монтировании компонента

    // Групируем данные
    const [groupedData, setGroupedData] = useState([]);

    useEffect(() => {
        // Разбиваем на группы и добавляем флаг в начало группы.
        // Проверка наличия списка и его длины перед сортировкой
        if (data && data.length > 0) {
            // console.log(data);
            // Ваш список целевых carType, перед которыми нужно вставить новые записи
            const targetCarTypes = ["tractor", "truck", "harvester", "snail", "tanker", "vehicle", "other"];

            // Сортировка списка по полю carType
            data.sort((a, b) => a.car_type.localeCompare(b.car_type));

            // Добавляем флаги в начало групп (костыль)
            targetCarTypes.forEach(targetCarType => {
                // Найти индекс первой записи с указанным carType
                const targetIndex = data.findIndex(item => item.car_type === targetCarType);

                // Проверка, чтобы избежать ошибок, если элемент не найден
                if (targetIndex !== -1) {
                    // Присваиваем флаг первой записи в отсортированном списке
                    data[targetIndex].group = true;
                }
            });

            setGroupedData(data);
        } else {
            console.error("Список автомобилей не инициализирован или пуст.");
        }
    }, [data]);

    // const handleClick = () => {
    //     // Запрашиваем ввод от пользователя
    //     const userInput = prompt("Введите значение:");

    //     // Если пользователь ввел что-то и не нажал "Отмена"
    //     if (userInput !== null) {
    //         // Сохраняем введенные данные в Local Storage с ключом 'car'
    //         setCarNumber(userInput);
    //         localStorage.setItem('carNumber', userInput);
    //     }
    // };

    return (
        // <Box sx={{ position: 'absolute', right: 0, top: 0, zIndex: 1000, bgcolor: 'background.paper' }}>
        <Card sx={{ minWidth: 105, maxHeight: 64, p: 0.5, borderRadius: 2, }}>

            <Typography fontSize={12}>
                Авто:
            </Typography>
            <Select
                value={selectedItem}
                onChange={handleChange}
                // label="Age"
                sx={{ maxHeight: '25px' }}
            >
                <MenuItem key={""} value={""}>Все</MenuItem>
                {groupedData &&
                    groupedData.map((car) =>
                        car.group ? (
                            <ListSubheader key={car.car_type} sx={{
                                bgcolor: 'background.dark',
                                fontWeight: 'bold',
                                fontSize: 20,
                                // color: 'orange', // Добавлен цвет текста для контраста
                                borderBottom: '1px solid #555', // Добавлен разделитель
                            }}>
                                {car.car_type}
                            </ListSubheader>
                        ) : (
                            <MenuItem key={car.as_id} value={car.as_id}>
                                {car.number}
                            </MenuItem>
                        )
                    )}
            </Select>
        </Card>
    );
};

export default CarNumber;