// Панель управления навигацией
// Располагается в меню и открывается по клику
import React, { useEffect, useState, useContext } from 'react';

import { Card, CardActionArea, Button, } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import NavigationIcon from '@mui/icons-material/Navigation';

// Работаем через контекст
import Context from '../Context/PointsContext';

export default function Navigation() {
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(null);

    const { context, setContext } = useContext(Context);
    const route = context?.route

    // const handleOpen = () => {
    //     console.log('Navigation');
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const handleFinishRoute = () => {

    //     setContext(prevState => ({
    //         ...prevState,
    //         'route': {
    //             // Сохраняем ранее занесенные сообщения
    //             ...prevState.route,
    //             // Меняем статус
    //             'status': !route.status
    //         }
    //     }))
    //     if (route.status) {
    //         setStatus()
    //     }
    //     // else {
    //     //     setStatus('Построить маршрут')
    //     // }
    // };


    return (
        <Card sx={{ minWidth: 140, maxHeight: 64, p: 0.5, borderRadius: 2 }}>
            <Typography>
                {/* {route?.A[0] ? `${route.A[0]?.toFixed(4)}, ${route.A[1]?.toFixed(4)}` : 'A: Включите GPS'} */}
                {route.A[0] ? route.A_name : 'A: Включите GPS'}
            </Typography>
            <Typography>
                {/* {route?.B[0] ? `${route.B[0]?.toFixed(4)}, ${route.B[1]?.toFixed(4)}` : 'B: Выберите задание'} */}
                {route.B[0] ? route.B_name : 'B: Выберите задание'}
            </Typography>
            {/* <CardActionArea onClick={handleOpen}>
                <NavigationIcon fontSize='large' />
            </CardActionArea> */}

            {/* fullScreen
            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Навигация</DialogTitle>

                <DialogContent >
                    <Typography>
                        От: {route?.A[0]?.toFixed(4)}, {route?.A[1]?.toFixed(4)}
                        {route?.A[0]}, {route?.A[1]}
                    </Typography>

                    <Typography>
                        До: {route?.B[0]?.toFixed(4)}, {route?.B[1]?.toFixed(4)}
                    </Typography>
                    <Button onClick={handleFinishRoute} variant="contained" disabled={status}>Завершить маршрут</Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog> */}
        </Card>
    )
}