// Кнопка "Домой"

// * При клике строится маршрут от текущего местоположения до домашней точки.
// * Выпадающий список с настройками при клике на шестеренку.

import React, { useEffect, useState, useContext } from 'react';
import { Box, Card, CardActionArea } from "@mui/material";

import PointsContext from '../Context/PointsContext';

import Alerts from '../Tools/Alerts'

export default function Home() {
    // const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);

    // Обновляем координаты точки B
    const { context, setContext } = useContext(PointsContext);

    useEffect(() => {

    }, [])

    // const handleOpen = () => {
    //     console.log('Settings');
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleHome = () => {

        // setContext(localStorage.getItem('home') || [50.206, 127.928])
        setContext(prevState => ({
            ...prevState,
            'route': {
                ...prevState.route,
                'B': localStorage.getItem('home') || [50.206, 127.928],
                'B_name': 'B: Домой'
            }
        }))

        if (!context?.A) {
            // setData('Включите GPS')
            console.log('Включите GPS');
        }
        else {
            // console.log('Строим маршрут домой:', context?.B);
        }

    };

    // Устанавливаем позицию дома.
    // localStorage.setItem('home', val)


    return (
        <Card onClick={handleHome} sx={{ minWidth: 100, maxHeight: 64, p: 0.5, borderRadius: 2 }} >
            <Box sx={{ display: 'flex' }}>
                <CardActionArea >
                    Домой (Толстовка)
                </CardActionArea>

                {/* {data ? <Alerts info={data} /> : null} */}

            </Box>

        </Card>
    )
}

// Настройка домашней точки
function SetHome() {
    const cachedData = localStorage.getItem('/fields');
    return (
        <Box>

        </Box>

    )
}